@import "./../../common/styles";
@import "bar";
@import "global";
//@import "layer";
//@import "master";

body .body-wrapper {
  opacity: 0;
  transition: opacity 300ms cubic-bezier(0.4, 0, 0.2, 1);
}

body.ready .body-wrapper {
  opacity: 1;
  min-height: 100%;
  display: flex;
  flex-direction: column;
}

.hide {
  display: none;
}

a.applink:hover {
  border: 2px dotted #dce6f4;
  padding: 2px;
  background-color: #ffff00;
  color: green;
  text-decoration: none;
}
a.applink {
  border: 2px dotted #dce6f4;
  padding: 2px;
  color: #2f5bff;
  background: transparent;
  text-decoration: none;
}
a.info {
  color: #2f5bff;
  background: transparent;
  text-decoration: none;
}
a.info:hover {
  color: green;
  background: transparent;
  text-decoration: underline;
}

a.supportDropdown {
  position: absolute;
  left: 165px;
  top: 20px;
}

a.color-link {
  color: var(--primaryColor);

  &:hover {
    text-decoration: underline;
  }
}

/* ***** promo ****** */
.promo-page {
  .banner {
    background-color: #000;
    text-align: center;
    img {
      width: 100%;
    }
  }

  .promo-box {
    position: relative;
    padding-top: 43px;
    padding-bottom: 50px;

    &:before {
      position: absolute;
      content: "";
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      background: #7a4d3a url(../img/promo_bg.png) center top no-repeat;
      z-index: -1;
      height: 110%;
    }

    &:after {
      content: "";
      position: absolute;
      height: 100px;
      top: -50px;
      left: 0;
      right: 0;
      background: linear-gradient(to bottom, rgba(0, 0, 0, 0.01), #7a4d3a, #7a4d3a, rgba(0, 0, 0, 0.01));
      z-index: 1;
    }

    .promo-outer-wrapper {
      max-width: 1268px;
      margin: auto;
      //background: #fff;
      //border-radius: 20px;
      //box-shadow: 0 0 10px 5px #c1c1c1;
    }

    .promo-wrapper {
      padding: 10px;
      margin: auto;
      max-width: 1100px;
      .icon {
        cursor: pointer;
        img {
          width: 100%;
        }
      }
      .text {
        background: #fff;
        padding: 20px;
        box-sizing: border-box;
      }
      a {
        color: #fff000;
      }
    }
  }
}

.cooperation-row {
  font-size: 16px;
  line-height: 28px;

  .cooperation-row-title {
    color: #990000;
    font-size: 14px;
    font-weight: bold;

    padding-top: 10px;
  }

  .cooperation-list {
    display: flex;

    > div {
      text-align: left;
    }

    .cooperation-title {
      flex-basis: 150px;
      font-weight: bold;

      &:after {
        content: ":";
      }
    }

    .cooperation-qrcode {
      width: 150px;
      background: white;
    }
  }
}
