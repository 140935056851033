* {
  margin: 0px;
  padding: 0px;
}

div,
table,
tr,
td,
img,
ul,
li,
h1,
h2,
h3,
h4,
h5,
h6,
p,
em,
font,
span,
dl,
dt,
dd,
form,
textarea,
input,
form,
cite {
  margin: 0;
  padding: 0;
}

ul,
ol {
  list-style-type: none;
}

h1,
h2,
h3,
h4,
h5 {
  font-weight: 500;
}

img {
  border: none;
}

.hidden {
  display: none;
}

a {
  color: #333333;
  text-decoration: none;
  &:hover {
    color: #1174b5;
    text-decoration: none;
  }
}

.undis {
  display: none;
}

.dis {
  display: block;
}

.fl {
  float: left;
  display: inline;
}

.fr {
  float: right;
  display: inline;
}

.w {
  margin: 0px auto;
  width: 1180px;
}

.clear {
  clear: both;
  overflow: hidden;
  height: 0px;
}

.kf_t {
  width: 145px;
  height: auto;
  border: 1px solid rgba(255, 255, 255, 0.5);
  text-align: center;
  position: fixed;
  left: 50%;
  margin-left: 480px;
  top: 122px;
  padding-top: 7px;
  div {
    margin-top: 4px;
    left: 0;
    right: 0;
    margin: auto;
  }
  .qr-text {
    margin: 8px auto;
    img {
      width: 130px;
    }
  }
  .qr-code {
    canvas {
      width: 100% !important;
      height: 100% !important;
    }
  }

  .kefuapp {
    background: #ae511c;
    border-radius: 3px;
    box-shadow: 1px 1px 1px #314f77;
    display: block;
    color: #fff;
    font-size: 14px;
    margin: 0 auto 5px;
    padding: 4px 0;
    position: relative;
    width: 62%;
    &:hover {
      .popup-container {
        transform: scale(1);
      }
    }
  }

  .popup-container {
    background-color: #fff;
    color: #000;
    line-height: 16px;
    margin: 0;
    padding-bottom: 5px;
    position: absolute;
    left: -170px;
    top: 0;
    width: 165px;
    word-break: break-all;
    transform: scale(0);
    transform-origin: right top;
    transition: all 200ms cubic-bezier(0.4, 0, 0.2, 1);
  }

  .morse {
    position: relative;
    height: 27px;
    width: 91px;
    background: #3a393a url("../img/icon-morse.svg") center center / 50px no-repeat;
    border-radius: 3px;
    img {
      position: absolute;
      right: calc(100% + 50px);
      opacity: 0;
      visibility: hidden;
      top: 0;
      transition: all 300ms ease-in-out;
    }

    &:hover {
      img {
        visibility: visible;
        opacity: 1;
      }
    }
  }
}

.push-10 {
  margin-top: 10px;
}

.appqr-wrapper .appqrcode {
  width: 130px;
  background-color: #fff;
  padding: 8px;
  box-sizing: border-box;
  img {
    width: 110px;
    height: 110px;
    background-color: #fff;
  }
}
