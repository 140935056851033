html {
  background: url(../img/bg2.jpg) no-repeat center top fixed;
  background-size: cover;
}

body,
div,
dl,
dt,
dd,
ul,
ol,
li,
h1,
h2,
h3,
h4,
h5,
h6,
pre,
form,
fieldset,
input,
select,
textarea,
blockquote,
th,
td,
label,
span,
strong,
p {
  margin: 0;
  padding: 0;
  //color: #666666;
  font-size: 12px;
  font-family: "Microsoft Yahei", 宋体, Arial, Verdana, sans-serif;
}

body {
  margin: 0px;
  padding: 0px;
}

img {
  border: 0px;
}

a {
  outline: none;
  /* for Firefox Google Chrome  */
  behavior: expression(this.onFocus=this.blur());
  /* for IE */
}

input,
select {
  height: 16px;
  vertical-align: middle;
}

fieldset {
  margin-top: 15px;
  padding: 10px 0px;
  width: 698px;
  border: 1px solid #999999;
  overflow: hidden;
  legend {
    margin: 0px 12px;
    padding: 2px 5px;
    color: #000;
    font-size: 14px;
  }
  table {
    margin-top: 0px;
    th,
    td {
      padding: 2px 5px;
    }
    th {
      padding: 2px 0px;
      width: 150px;
      text-align: right;
      font-weight: normal;
      span {
        margin: 0px 5px;
        color: #ff0000;
        font-weight: bold;
      }
    }
  }
}

/*input[type="submit"],input[type="button"],input[type="reset"] { cursor:pointer;font-family:宋体,Verdana,Arial; }

*/

table {
  border-collapse: collapse;
  border-spacing: 0px;
}

/*::selection { background:#950a03;color:#fff;text-shadow:none; }*/

a {
  &:link,
  &:visited {
    color: #666666;
    text-decoration: none;
  }
  &:hover {
    color: #ff0000;
    text-decoration: none;
  }
  &.black {
    &:link,
    &:visited {
      color: #666666;
      text-decoration: underline;
    }
    &:hover {
      color: #ff0000;
      text-decoration: underline;
    }
  }
  &.red {
    &:link,
    &:visited {
      color: red;
      text-decoration: none;
    }
    &:hover {
      color: #af0101;
      text-decoration: none;
    }
  }
  &.red2 {
    &:link,
    &:visited {
      color: red;
      text-decoration: underline;
    }
    &:hover {
      color: #af0101;
      text-decoration: underline;
    }
  }
  &.blue {
    &:link,
    &:visited {
      color: blue;
      text-decoration: none;
    }
    &:hover {
      color: #0101af;
      text-decoration: none;
    }
  }
}

.noRecord {
  height: 50px;
  line-height: 50px;
  color: #ff7200;
  text-align: center;
}

.form-tips {
  color: #eeb7b7;
}

.Loding {
  height: 16px;
  padding: 13px 0px 0px 30px;
  color: #a8ff00;
  text-align: left;
}

.imeDisable {
  ime-mode: disabled;
}

.errTips {
  display: none;
  margin-left: 5px;
  padding: 0px 10px 1px 5px;
  line-height: 20px;
  border: 2px solid #ffb4b7;
  background-color: #fbe3e4;
  color: #ff0000;
  font-style: italic;
}

.ok-tips {
  display: block;
  padding: 2px 5px;
  background: url(http://404ident331.com/) no-repeat left center;
  color: green;
  text-indent: 15px;
}

.form-send-tips {
  display: none;
  line-height: 24px;
  border: 1px solid #fdd6bd;
  background: #ffeee2 url(http://404ident331.com/) no-repeat 5px 5px;
  border-radius: 3px;
  text-indent: 25px;
  color: #ff6600;
}

.form-send-tips-err {
  border: 1px solid #f7bda5;
  background: #ffded0 url(http://404ident331.com/) no-repeat 6px 6px;
  color: #ff4e00;
}

.form-send-tips-ok {
  border: 1px solid #99ec70;
  background: #d8f5c9 url(http://404ident331.com/) no-repeat 6px 6px;
  color: #3ab100;
  span {
    color: #ff0000;
    font-weight: bold;
  }
}

/*返回顶部*/

#floatTop {
  background: url(http://404ident331.com/) no-repeat;
  cursor: pointer;
  display: none;
  position: fixed;
  z-index: 20;
  right: 30px;
  bottom: 30px;
  width: 42px;
  height: 43px;
}

.ui-top-nav {
  width: 270px;
  height: auto;
  background: url(../img/login_win.png) no-repeat;
  float: left;
  dl {
    width: 100%;
    overflow: hidden;
    dt {
      float: left;
      width: 435px;
      height: 45px;
      line-height: 45px;
      background: url(../img/ico-comment.png) no-repeat left center;
      overflow: hidden;
      span {
        float: left;
        display: block;
        width: 72px;
        height: 100%;
        text-align: right;
        color: #b6985a;
      }
      marquee {
        float: right;
        width: 360px;
        height: 100%;
      }
    }
    dd {
    }
  }
}

.ui-web-nav {
  position: fixed;
  z-index: 100;
  left: 0px;
  top: 46px;
  width: 100%;
  height: 87px;
  background: url(http://404ident331.com/) no-repeat center bottom;
  dl {
    margin: 0 auto;
    width: 1000px;
    overflow: hidden;
    dt {
      float: left;
      width: 250px;
      overflow: hidden;
    }
    dd {
      float: right;
      padding-top: 36px;
      height: 33px;
      overflow: hidden;
      a {
        &:link,
        &:visited {
          float: left;
          display: block;
          width: 101px;
          height: 33px;
          line-height: 24px;
          color: #fff;
          text-align: center;
          font-size: 14px;
        }
        &:hover,
        &.cur {
          background: url(http://404ident331.com/) no-repeat center top;
          color: #640b26;
        }
      }
    }
  }
}

.login-frm {
  width: 100%;
  height: 250px;
  padding-top: 60px;
}

/*.login-frm div { position:relative; margin:0px 2px; width: 110px;height:24px;background:#fff; }
.login-frm div label { float:left;cursor:text;position:absolute;left:5px;top:0px;line-height:24px;color:#c2c2c2;font-size:14px; }
.login-frm div img { cursor:pointer;float:right;margin:1px 1px 0px 0px;width:50px;height:22px; }
.login-frm input { outline:none;float:left;border:0px;padding:0px;height:24px;line-height:24px;background:#fff;color:#666666;font-size:14px; }
.login-frm .txt-box { padding-left:5px;width:90px; }
.login-frm .txt-box2 { padding-left:5px; width: 40px; }
.login-frm #loginSubmitBtn { cursor:pointer;margin-left:2px;width:50px;height:24px;background:url(../img/login-button.jpg) no-repeat center center; }
.login-frm a { float:left;margin-left:10px;line-height:24px;color:#fff; }
.login-frm a:hover { color:#ffcc00; }
.login-frm a.forgot-pwd { margin:0px; }*/

.ui-footer {
  padding-top: 35px;
  width: 100%;
  min-width: 1000px;
  height: 90px;
  background: url(http://404ident331.com/) repeat-x;
  dl {
    margin: 0px auto;
    width: 1000px;
    overflow: hidden;
    dt {
      height: 40px;
      text-align: center;
      color: #c79697;
      overflow: hidden;
      a {
        margin: 0px 10px;
        color: #c79697;
        &:hover {
          color: #fff;
        }
      }
    }
    dd {
      height: 23px;
      background: url(http://404ident331.com/) no-repeat center top;
    }
  }
}

.footer-img {
  width: 100%;
  min-width: 1000px;
  height: 430px;
  background: url(http://404ident331.com/) no-repeat center top;
}

.nav {
  margin: 0 auto;
  height: 18px;
  background: #ae1a14;
}

.hua {
  width: 213px;
  height: 256px;
  position: absolute;
  left: 0;
  top: 115px;
  background: url(../img/hua.png);
}

.foot {
  height: 152px;
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
}

/*首页*/

#homeBanner {
  position: relative;
  width: 100%;
  min-width: 1000px;
  height: 541px;
  background: url(http://404ident331.com/) no-repeat center bottom;
  overflow: hidden;
  .banner {
    position: absolute;
    z-index: 1px;
    width: 100%;
    min-width: 1000px;
    height: 537px;
  }
  .ctrl {
    margin: -50px 0px 0px -480px;
    left: 50%;
    top: 50%;
    width: 940px;
    height: 100px;
    position: absolute;
    z-index: 999;
    a {
      background: url(http://404ident331.com/) no-repeat;
      width: 30px;
      height: 100px;
      display: block;
      position: absolute;
      z-index: 999;
      opacity: 0.1;
      -moz-opacity: 0.1;
      overflow: hidden;
      text-indent: -200px;
      &:hover {
        opacity: 0.5;
        -moz-opacity: 0.5;
      }
      &#prev {
        left: -30px;
      }
      &#next {
        background-position: -30px 0px;
        right: -30px;
      }
    }
  }
}

.home-lottery {
  width: 100%;
  padding-top: 40px;
  height: 350px;
  background: url(http://404ident331.com/) no-repeat center top;
  div {
    margin: 0 auto;
    width: 1000px;
    height: 300px;
    a {
      float: left;
      display: block;
      width: 250px;
      height: 150px;
      overflow: hidden;
      text-indent: -500px;
      &.game1,
      &.game2,
      &.game3,
      &.game4,
      &.game5,
      &.game6,
      &.game7,
      &.game8 {
        background: url(http://404ident331.com/) no-repeat;
      }
    }
  }
}

/*栏目*/

.menu-main-warp {
  position: relative;
  margin-top: 98px;
  width: 100%;
  min-width: 1000px;
  background: url(http://404ident331.com/) repeat-y;
  overflow: hidden;
}

.menu-main-l {
  position: absolute;
  left: 0px;
  padding-top: 30px;
  width: 200px;
  overflow: hidden;
  ul {
    list-style: none;
    overflow: hidden;
    li {
      float: left;
      margin: 0px 10px;
      height: 36px;
      line-height: 36px;
      border-bottom: 1px solid #d2d1d1;
      font-size: 14px;
      a {
        float: left;
        padding-left: 20px;
        display: block;
        width: 166px;
        height: 34px;
        color: #333333;
        &:hover {
          background: url(http://404ident331.com/) no-repeat;
        }
        &.cur {
          background: url(http://404ident331.com/) no-repeat;
          &:hover {
            background: url(http://404ident331.com/) no-repeat;
          }
        }
      }
    }
  }
}

.menu-main-r {
  margin-left: 200px;
  padding: 20px;
  overflow: hidden;
}

.menu-title {
  line-height: 45px;
  border-bottom: 2px solid #d2d1d1;
  font-size: 18px;
  overflow: hidden;
}

.menu-title2 {
  width: 700px;
  line-height: 45px;
  border-bottom: 2px solid #d2d1d1;
  font-size: 18px;
}

.agent-frm h1 {
  margin-top: 10px;
  font-size: 20px;
}

/*Info Content*/

.info-cont {
  padding-top: 15px;
  line-height: 2em;
  white-space: normal;
  text-justify: inter-ideograph;
  text-align: justify;
  h1 {
    margin-top: 10px;
    font-size: 20px;
  }
  h2 {
    margin-top: 10px;
    font-size: 16px;
  }
  h3 {
    margin-top: 10px;
    font-size: 14px;
  }
  h4 {
    margin-top: 10px;
  }
  p {
    margin: 10px 0px 0px 0px;
  }
}

/*代理加盟*/

.apply-class {
  margin-left: 40px;
  padding: 20px 0px 0px 0px;
  width: 918px;
  height: 35px;
  a {
    float: left;
    display: block;
    margin-right: 10px;
    padding: 0px 20px;
    height: 25px;
    line-height: 25px;
    border: 1px solid #ffbd5b;
    background: #331304;
    color: #ffeed6;
    text-align: center;
    font-size: 14px;
    font-weight: bold;
    &:hover {
      border: 1px solid #ffbd5b;
      background: #b0521e;
      color: #fff;
    }
    &.seled {
      border: 1px solid #ffbd5b;
      background: #b0521e;
      color: #fff;
      &:hover {
        color: #3e220f;
      }
    }
  }
}

.apply-tab {
  width: 100%;
  border: 0px;
  border-top: 1px solid #1e63bd;
  border-left: 1px solid #1e63bd;
  th,
  td {
    line-height: 30px;
    border: 0px;
    border-right: 1px solid #1e63bd;
    border-bottom: 1px solid #1e63bd;
    background: #003374;
    text-align: center;
  }
  th {
    background: #002658;
    color: #fff;
  }
}

/*规则说明*/

.rule-info {
  margin: 25px auto 30px auto;
  width: 940px;
  overflow: hidden;
  ul {
    width: 940px;
    overflow: hidden;
    list-style: none;
    li {
      float: left;
      margin-top: 5px;
      width: 940px;
      overflow: hidden;
      img {
        float: left;
        width: 940px;
      }
      div {
        float: left;
      }
      .min-banner {
        width: 940px;
        cursor: pointer;
      }
    }
  }
  .rule-cont {
    display: none;
    padding: 5px 0px;
    line-height: 2em;
    overflow: hidden;
  }
}

/*手机投注*/

.mobile {
  margin: 0px auto;
  width: 1000px;
  height: 480px;
  overflow: hidden;
  /*background:#8e6617;box-shadow:0px 0px 10px #242001;*/
  embed {
    float: left;
  }
}

input {
  &.inp-txt {
    height: 20px;
    line-height: 20px;
    padding: 0px 4px;
    border: 1px solid #707070;
    /*box-shadow:inset 2px 2px 4px #145bba;*/
    &:hover {
      border-color: #fea201;
    }
  }
  &.btnFace {
    cursor: pointer;
    width: 80px;
    height: 26px;
    line-height: 23px;
    *line-height: 15px;
    _line-height: 15px;
    border: 1px solid #053a09;
    background: url(http://404ident331.com/) repeat-x;
    color: #2f0a01;
  }
  &.inp-rd {
    margin-right: 5px;
  }
  &.rdFace {
    vertical-align: middle;
    cursor: pointer;
    margin-right: 5px;
    width: 12px;
    height: 12px;
    *width: 14px;
    *height: 14px;
    border: 1px solid #5799f2;
    background: #186dde;
  }
  &.rdFace-over {
    border: 1px solid #ff8400;
    box-shadow: 0px 0px 7px #c9c9c9;
  }
}

/*INPUT.btnFace-over,INPUT.btnFace2-over,INPUT.btnFace3-over,INPUT.btnFace:hover,INPUT.btnFace2:hover { background:url(../img/btnface.gif) no-repeat 0px -114px;border:1px solid #FFB400;color:#FF0000;box-shadow:0px 0px 7px #c9c9c9; }*/

textarea {
  padding: 4px;
  border: 1px solid #707070;
  overflow-y: auto;
  &:hover {
    border-color: #fea201;
  }
}

select {
  padding: 2px;
  height: 22px;
  border: 1px solid #707070;
}

.reg-btn,
.ag-btn {
  cursor: pointer;
  width: 90px;
  height: 28px;
  border: 1px solid #707070;
  background: url(http://404ident331.com/) repeat-x;
  color: #707070;
  &:hover {
    border: 1px solid #fea201;
    background-position: left bottom;
    color: #990000;
  }
}

.header {
  width: 950px;
  height: 100px;
  padding-top: 10px;
  margin: 0 auto 10px;
  position: relative;
}

.logo {
  width: 250px;
  height: 100px;
  position: absolute;
  left: -10px;
  top: 10px;
}

.notice {
  height: 30px;
  margin-top: 10px;
  overflow: hidden;
  margin-bottom: 10px;
  margin-left: 270px;
  position: relative;
}

.notice-div {
  width: 100%;
  height: 30px;
  line-height: 30px;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 10;
  color: #fff;
  span {
    width: 80px;
    height: 30px;
    line-height: 30px;
    color: #fff;
    font-size: 16px;
    font-weight: bold;
    padding-left: 10px;
    float: left;
  }
}

.scrollText {
  width: 590px;
  height: 30px;
  line-height: 30px;
  overflow: hidden;
  float: left;
  li {
    font-size: 14px;
    color: #fff;
  }
}

.notice-bg {
  width: 100%;
  height: 30px;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 9;
  background-color: rgba(0, 0, 0, 0.3);
  border-radius: 5px;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#66000000',endColorstr='#66000000',GradientType=0);
}

:root .notice-bg {
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#00000000',endColorstr='#00000000',GradientType=0);
}

.menu {
  height: 30px;
  line-height: 30px;
  margin-left: 250px;
  clear: both;
  ul {
    height: 30px;
    list-style: none;
    float: right;
  }
  li {
    width: 90px;
    height: 30px;
    line-height: 30px;
    list-style: none;
    text-align: center;
    float: left;
    a {
      width: 100%;
      height: 30px;
      line-height: 30px;
      display: block;
      color: #fff;
      font-size: 16px;
      font-weight: bold;
      &:hover {
        color: #fff;
        background: url("../img/menu.png") no-repeat;
      }
    }
  }
}

.main {
  width: 950px;
  height: 400px;
  margin: 0 auto;
  position: relative;
  z-index: 10;
}

.flash {
  width: 650px;
  height: 341px;
  margin: 0 15px;
  float: left;
  overflow: hidden;

  .swiper-container {
    width: 100%;
    height: 340px;
  }
  .swiper-slide {
    overflow: hidden;
    width: 100%;
    height: 100%;
    img {
      width: auto;
      height: 100%;
      position: relative;
      left: 50%;
      transform: translateX(-50%);
    }
  }
}

.login-ipt {
  background: url("../img/input_bg.png") no-repeat left top;
  height: 50px;
  width: 220px;
  margin: 10px auto;
  position: relative;
}

.after-login {
  #loginUsername {
    float: left;
    font-size: 16px;
    margin: 12px 0 0 0;
    width: 155px;
    font-family: Microsoft YaHei;
    color: #999;
    border: none;
    height: 25px;
    line-height: 25px;
  }
  .reg {
    display: flex;
    align-items: center;
    width: 210px;
    margin: 10px auto;
    padding: 0;
    box-sizing: border-box;
    a {
      flex-grow: 1;
      display: block;
      text-align: center;
      padding: 6px 8px;
      border-radius: 4px;
      color: #fff;
      background: linear-gradient(to bottom, #b4e16a, #42b233);
      &:not(:last-child) {
        margin-right: 4px;
      }
      &:hover {
        background: #42b233;
      }
    }
  }
}

.username {
  label {
    background: url("../img/login_ico.png") no-repeat top;
    display: block !important;
    font-size: 0;
    text-indent: -99999px;
    height: 35px;
    width: 38px;
    margin: 6px 0px 0 10px;
    opacity: 1 !important;
    filter: alpha(opacity = 100) !important;
    float: left;
  }
  input {
    //background: url("../img/username.gif") no-repeat left center;
  }
}

.input-txt {
  background: none !important;
}

.password {
  label {
    background: url("../img/login_ico.png") no-repeat center;
    display: block !important;
    font-size: 0;
    text-indent: -99999px;
    height: 35px;
    width: 38px;
    margin: 6px 0px 0 10px;
    opacity: 1 !important;
    filter: alpha(opacity = 100) !important;
    float: left;
  }
  input {
    //background: url("../img/password.gif") no-repeat left center;
  }
}

.login-ipt input {
  float: left;
  font-size: 16px;
  margin: 12px 0 0 0;
  width: 155px;
  font-family: Microsoft YaHei;
  color: #999;
  border: none;
  height: 25px;
  line-height: 25px;
}

.reg {
  padding-left: 30px;
  clear: both;
}

.login-btn {
  width: 120px;
  height: 52px;
  background: url(../img/login_bu.png) no-repeat left top;
  border: none;
  text-indent: -9999em;
  float: left;
  cursor: pointer;
  &:hover {
    background: url(../img/login_bu.png) no-repeat left -52px;
  }
}

.zhuce {
  width: 88px;
  height: 52px;
  background: url(../img/login_bu.png) no-repeat left -104px;
  display: block;
  text-indent: -9999em;
  float: left;
  margin-left: 8px;
  &:hover {
    background: url(../img/login_bu.png) no-repeat left -156px;
  }
}

.test {
  cursor: pointer;
  width: 221px;
  height: 46px;
  background: url(../img/login_bu.png) no-repeat left -208px;
  border: none;
  text-indent: -9999em;
  float: left;
  &:hover {
    background: url(../img/login_bu.png) no-repeat left -254px;
  }
}

.forgot-pwd {
  position: absolute;
  right: -22px;
  top: 10px;
}

.box {
  padding: 10px;
  line-height: 24px;
  strong {
    height: 30px;
    line-height: 30px;
    display: block;
    padding-top: 10px;
  }
}

.daili {
  width: 112px;
  height: 38px;
  line-height: 38px;
  text-align: center;
  border: 1px solid #992a2c;
  background: #992a2c;
  color: #fff;
  font-size: 15px;
  cursor: pointer;
  border-radius: 3px;
  font-family: "Microsoft YaHei";
  display: block;
  margin: 0 auto;
  &:link,
  &:visited {
    color: #fff;
  }
}

.footer {
  //position: absolute;
  //z-index: 199;
  //left: 0;
  //bottom: 50px;
  width: 100%;
  text-align: center;
  display: flex;
  flex-direction: column;
  margin-top: auto;
  margin-bottom: 30px;
  justify-content: center;
}

.copyright {
  margin-top: 15px;
  font-size: 14px;
  color: #95742e;
}

.payment-platform {
  height: 38px;
  overflow: hidden;
  padding: 14px 0 13px;
  background: url(../img/pay_bg.png) no-repeat center;
  img {
    margin: 0 12px;
    vertical-align: top;
  }
}

.promotitle {
  font-size: 14px;
  font-weight: bold;
}

.promocontent {
  b,
  strong {
    font-weight: bold;
  }
}

#promodiv {
  color: #666666;
}

.code label {
  background: url("../img/login_ico.png") no-repeat center;
  display: block !important;
  font-size: 0;
  text-indent: -99999px;
  height: 35px;
  width: 38px;
  margin: 6px 0px 0 10px;
  opacity: 1 !important;
  filter: alpha(opacity = 100) !important;
  float: left;
}

.logincode {
  float: right;
  margin-right: 10px;
  margin-top: 7px;
  position: absolute;
  right: -3px;
  top: 2px;
}

.loginico-pwd2 {
  display: inline-block;
  height: 31px;
  width: 46px;
  background: url(../img/login_ico.png) left -70px no-repeat;
  float: left;
  margin-left: 10px;
  margin-top: 8px;
}

.logintxt {
  width: 150px;
  height: 30px;
  margin-top: 5px;
  float: left;
  line-height: 28px;
  border: 0px;
  outline: none;
  background: none;
}

.register {
  .inp-txt {
    width: 340px;
    height: 34px;
    font-size: 20px;
    line-height: 34px;
    text-indent: 5px;
    border-top: 1px solid #b8b8b8;
    border-left: 1px solid #b8b8b8;
    border-right: 1px solid #cccccc;
    border-bottom: 1px solid #cccccc;
    margin: 0 15px 0 0;
  }
  .bu_reg {
    width: 202px;
    height: 43px;
    margin: 0 20px 0 115px;
  }
}
